@import "~@angular/material/theming";
@include mat-core();

$twentio-shoptet-primary: mat-palette(
  (
    50: #e4e9fe,
    100: #bbc8fb,
    200: #8ea3f9,
    300: #617ef7,
    400: #3f62f5,
    500: #1d46f3,
    600: #1a3ff1,
    700: #1537ef,
    800: #112fed,
    900: #0a20ea,
    A100: #ffffff,
    A200: #e0e3ff,
    A400: #adb3ff,
    A700: #949bff,
    contrast: (
      50: #000000,
      100: #000000,
      200: #000000,
      300: #000000,
      400: #ffffff,
      500: #ffffff,
      600: #ffffff,
      700: #ffffff,
      800: #ffffff,
      900: #ffffff,
      A100: #000000,
      A200: #000000,
      A400: #000000,
      A700: #000000,
    ),
  )
);
$twentio-shoptet-accent: mat-palette(
  (
    50: #ffffff,
    100: #ffffff,
    200: #ffffff,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
    contrast: (
      50: #ffffff,
      100: #ffffff,
      200: #ffffff,
      300: #ffffff,
      400: #ffffff,
      500: #ffffff,
      600: #ffffff,
      700: #ffffff,
      800: #ffffff,
      900: #ffffff,
      A100: #ffffff,
      A200: #ffffff,
      A400: #ffffff,
      A700: #ffffff,
    ),
  )
);
$twentio-shoptet-warn: mat-palette($mat-red);
$twentio-shoptet-theme: mat-light-theme(
  (
    color: (
      primary: $twentio-shoptet-primary,
      accent: $twentio-shoptet-accent,
      warn: $twentio-shoptet-warn,
    ),
  )
);

@include angular-material-theme($twentio-shoptet-theme);

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");
*,
::after,
::before {
  box-sizing: border-box;
}

body {
  margin: 0;
  background: #fafafa;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  position: relative;
  display: table;
  &.center-line {
    &:after {
      content: "";
      position: absolute;
      bottom: -0.5rem;
      left: 25%;
      width: 50%;
      height: 3px;
      background: #000;
    }
  }
  &.side-line {
    &:after {
      content: "";
      position: absolute;
      bottom: -0.5rem;
      left: 0;
      width: 75%;
      height: 3px;
      background: #000;
    }
  }
}

.container {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto;
  position: relative;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 80%;
    padding: 0;
  }
}

.is-floating-label {
  position: relative;
  display: flex;
  justify-content: flex-end;

  input {
    box-shadow: none;
    background: #fff;
    outline: 0;
    padding: 12px;
    font-size: 1rem;
    padding-bottom: 12px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    width: 100%;
    border-radius: 0;
    transition: 0.3s ease;
    border: 1px solid #1d46f3;

    &:focus {
      transition: 0.3s ease;
      border: 1px solid rgba($color: #000, $alpha: 0.74);
    }

    &.active{
      transition: 0.3s ease;
      border: 1px solid rgba($color: #000, $alpha: 0.74);
    }
  }
}

.border-button-mat{
  &.mat-raised-button{
    border-radius: 0 !important;
    box-shadow: none !important;
  }
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
